import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import { RideDetail as RideDetailPage } from '../components/RideDetail'

const RideDetail = ({ location }) => {
	const { state = {} } = location
	return (
		<Layout>
			<SEO title='Ride Detail' />
			<RideDetailPage state={state} />
		</Layout>
	)
}

export default RideDetail
