import gql from 'graphql-tag'

export const getRideDetail = gql`
	query($id: String!) {
		ride(id: $id) {
			id
			pickupDate
			status
			date
			pickupLoc
			destLoc
			custPhone
			custFullName
			rideType
			driverId
			billType
			numberOfPassenger
			totalFare
			status
			startTime
			endTime
			distance
		}
	}
`
export const getDriverName = gql`
	query($id: String!) {
		driver(id: $id) {
			firstName
			lastName
			email
			phone
			taxiName
			taxiModel
			taxiLicenseNo
			taxiColor
		}
	}
`
export const cancelRide = gql`
	mutation($id: ID!) {
		cancelRide(id: $id) {
			id
			date
			pickupLoc
			destLoc
			custPhone
			custFullName
			rideType
			billType
			numberOfPassenger
			totalFare
			status
			startTime
			endTime
		}
	}
`
