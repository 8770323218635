import React, { useEffect } from "react";
// import { Query } from "react-apollo";
import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";
import * as moment from "moment";

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  dashBoard: {
    flex: 1,
    marginHorizontal: "3%",
    marginTop: "15%",
    backgroundColor: "#f5f5f5",
  },
  blueHeader: {
    width: "100%",
    height: 150,
    backgroundColor: "#0B1B3F",
  },
  absolutePositionBox: {
    width: "100%",
    height: 550,
    position: "absolute",
    backgroundColor: "#fff",
    marginTop: "10%",
    marginRight: "27%",
    marginLeft: "5%",
  },
  widthAndHeight: {
    width: "100%",
    height: "100%",
  },
});

const Invoice = ({ driverData, rideData, bookingNumber }) => {
  console.log(driverData + "  " + rideData);
  let splitLocation1 = rideData.pickupLoc.split(",");
  let firstValue1 = splitLocation1[0];
  let restOfArray1 = splitLocation1.slice(1).toString();

  let splitLocation2 = rideData.destLoc.split(",");
  let firstValue2 = splitLocation2[0];
  let restOfArray2 = splitLocation2.slice(1).toString();

  return (
    <Document>
      <Page
        size="A4"
        style={{
          paddingTop: 5,
          backgroundColor: "white",
        }}
      >
        <View
          style={{
            flex: 1,
            backgroundColor: "white",
          }}
        >
          <Text
            style={{
              padding: 15,
              textAlign: "center",
              fontfamily: "Poppins",
              fontStyle: "normal",
              fontWeight: "bold",
              fontSize: "21",
              color: "#080a61",
            }}
          >
            Receipt Booking #{bookingNumber.toUpperCase()}
          </Text>

          <View
            style={{
              backgroundColor: "#080a61",
              paddingTop: 5,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 5,
            }}
          >
            <Text
              style={{
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "16",
                paddingTop: 10,
                color: "#fff",
              }}
            >
              Outward ride
            </Text>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  paddingTop: 7,
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  fontStyle: " normal",
                  fontWeight: "bold",
                  fontSize: " 15",
                  color: "#707070",
                }}
              >
                {new Date(parseInt(rideData.date)).toLocaleString()}
              </Text>
              <Text
                style={{
                  whiteSpace: "nowrap",
                  paddingTop: 7,
                  textAlign: "left",
                  fontStyle: " normal",
                  fontWeight: "normal",
                  fontSize: " 16",
                  color: "#fff",
                }}
              >
                € {rideData.totalFare}
              </Text>
            </View>
          </View>

          {/*------ LOCATION CONTAINER 1 HERE ---- */}
          <View
            style={{
              flexDirection: "row",
              backgroundColor: "white",
              marginHorizontal: 15,
              marginTop: 15,
              paddingLeft: 10,
            }}
          >
            <Image
              style={{
                width: 20,
                height: 30,
              }}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAeCAYAAAAo5+5WAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAFqADAAQAAAABAAAAHgAAAAAPmVqhAAACeElEQVRIDaVWzXUTQQyWJrdwwB0kVMAmDeAcQ+DFVIDpACrI0oE7iFMB8QM/jrEbwEsFOB3EB26shKTN5M3OT8I+9jKjT58+f7MzozVC4eHv1SEQnDPjRCgVII6A+U7mDQM2DukKz5qmUA4YJ1SQyF1IYhrnkph5hcyf8W2zinM9YV5WlTi8MXcx85EYgT6I+3lIeRA2UXCbMDlkjgyf8M2Pma8xYV0+t7gZ6tSL+BGJTvxrcQpSi3VZlNe2VC1CesfMV14oHtnhpcfQ3JL75YFg3InQFF831wFm0/u9mIuZl3HOv28HrZvESY1LopaTY4Z7rHU7jcNHjqLpOUYjhDmZy/IzTkMSnjZbBpiFWDfHcx3lHWMVJxF4HmO52AFd53B9vbp5z5MkwTbBMkDx5v0BE05L9mCUgimizlK0Q5zc/59xkqjbgBhPYoJxggmgZ1k2D1dxEhHf65GK8TDmm2okJ+AixGx+b9RJl1olSQGk6LK0VBP9jV/E26FQe48YtQ21K03Lo62QDnoMDaRNivuZ3M2FbpT9UAuvpLjOiWoJOnqhR9GE2+VxLZN0Wcoc9PDanW3GWmK9wu2TuEpvkRKGPEhce74J40lzl79FnvYvo9zWoOGbsJb9r+vQrel5L+paOtNHHw8ZtZWGbnvCGnSfF14PERXuzj3jxNDDq/Bi6HiqZB8/NVp7ldXGvFRYzyBDHRPzMS9K7TURVoHuo8iLvJhH+Rb3bXUe6I1ZYWV0RXzbYweB9OyJbngA9aZlYTslua+L/Gj3P6LpKUVBUVh52h9UJKyxoxX9OQnzg+bSS2a0PGb6dvSoy0Ginsxfq7G2Sx8/Nf4F6Kn+9QPMqtEAAAAASUVORK5CYII="
            />
            <Text
              style={{
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: " normal",
                fontWeight: "normal",
                fontSize: "16",
                color: "#080a61",
                paddingLeft: 20,
                paddingTop: 7,
              }}
            >
              {moment(parseInt(rideData.startTime)).format("LTS")}
            </Text>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  fontStyle: " normal",
                  fontWeight: "normal",
                  fontSize: "16",
                  color: "#080a61",
                  paddingLeft: 20,
                  paddingTop: 7,
                }}
              >
                {firstValue1}
              </Text>
              <Text
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16",
                  color: "#0001",
                  opacity: 0.6,
                  paddingLeft: 20,
                  paddingTop: 7,
                }}
              >
                {restOfArray1}
              </Text>
            </View>
          </View>

          {/*------ LOCATION CONTAINER 2 HERE ---- */}

          <View
            style={{
              flexDirection: "row",
              backgroundColor: "white",
              marginHorizontal: 15,
              marginTop: 15,
              paddingLeft: 10,
            }}
          >
            <Image
              style={{
                width: 20,
                height: 30,
              }}
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABYAAAAeCAYAAAAo5+5WAAAAAXNSR0IArs4c6QAAADhlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAAqACAAQAAAABAAAAFqADAAQAAAABAAAAHgAAAAAPmVqhAAACfklEQVRIDaVW0XEaMRBdSfg7MPF/iFNA3EFIBSYz5ju4g6QCkw7owPCNM8EVBFdgUgDJpQAG+IbTZp9A9p2kY7iJfqR9u/t40q50KKoYq267bc/0FbPqKuJLUqpJzGsmNRd7rpUdtybZvCKdVOg4EN4SqX7oC21mmhnKv7Xus1noKxGveu1Ly/qnUxdGHrGZ6OZ8shgVQ56JHSmZp6Kz1pr56+v730Of44jd9hv6qa5ST+JnzflHfywaoG2YQRWpbPMRW0USW/pETGNPFM5W6TuPqX2xzB8PvMy8Yav6598X0xdsv9ofmx5Jgd+HPojAeWvb0N3QCbuKFD60md5ayeMN7NJgcnyalYqI5VcfU0qLBK1plsmxDIsY1krRFWbtmh+r8hiVzbQll2Sa8uB4pXjqVeg0nGchlrIrb16DQByPnE0zRmMEymJ0jwgx/wqdck7RuYcxsPMz00nh6GUonkVORZ/RUhFeAERtU+pzW4AOy71QLW01i51yaUjfVW0VpHnD/JD6tKNcJldQd6WX1xeZ9MmbOIjXgg2l+g8oFH4oN+aD0jxIkkqw3uZv0YqeeCDEiW1FP3UUcP0/WXQQ5LpC7+wweYuO0sROadOBRx2xSF+nbpEPOmWGWv+yId4Ru8V/qi6qLRFDtXzPvgCsPeQpLapF/rNiGHjusCWsTx+80bs8ElQiBpnZ5v06hcTz6moUKImI0YNSyEEQlzTlK/1Q9bxGxGDARxFJSTYPMv81O+wuPZLECHVJkpxOk+Io200dgY+vJEYSkn1gcZYC31S+xYfASmL4kQySIqmc/zj8c1Ly1zGWvYvhsveOZZ7XyTspdnXd7uC5PClYgv4BC2AMckEwjkYAAAAASUVORK5CYII="
            />
            <Text
              style={{
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: " normal",
                fontWeight: "normal",
                fontSize: "16",
                color: "#080a61",
                paddingLeft: 20,
                paddingTop: 7,
              }}
            >
              {moment(parseInt(rideData.endTime)).format("LTS")}
            </Text>
            <View style={{ flexDirection: "column" }}>
              <Text
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  fontStyle: " normal",
                  fontWeight: "normal",
                  fontSize: "16",
                  color: "#080a61",
                  paddingLeft: 20,
                  paddingTop: 7,
                }}
              >
                {firstValue2}
              </Text>
              <Text
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16",
                  color: "#0001",
                  opacity: 0.6,
                  paddingLeft: 20,
                  paddingTop: 7,
                }}
              >
                {restOfArray2}
              </Text>
            </View>
          </View>

          {/*------ DISTANCE AND TIME CONTAINER HERE ---- */}
          <View
            style={{
              backgroundColor: "#e4e6eb",
              height: "56dp",
              flexDirection: "row",
              marginHorizontal: 15,
              marginTop: 20,
            }}
          >
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                paddingHorizontal: 10,
                justifyContent: "space-between",
              }}
            >
              <Text
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  fontStyle: " normal",
                  fontWeight: "bold",
                  fontSize: "16",
                  color: "#080a61",
                }}
              >
                Distance:
              </Text>
              <Text
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16",
                  color: "#0001",
                  opacity: 0.6,
                }}
              >
                {rideData.distance} KM
              </Text>
              <Text
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  color: "#0001",
                  opacity: 0.6,
                }}
              >
                |
              </Text>
              <Text
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  fontStyle: " normal",
                  fontWeight: "bold",
                  fontSize: "16",
                  color: "#080a61",
                }}
              >
                Trip Time:
              </Text>
              <Text
                style={{
                  whiteSpace: "nowrap",
                  textAlign: "left",
                  fontStyle: "normal",
                  fontWeight: "normal",
                  fontSize: "16",
                  color: "#0001",
                  opacity: 0.6,
                }}
              >
                {moment
                  .utc(
                    moment(parseInt(rideData.endTime)).diff(
                      moment(parseInt(rideData.startTime))
                    )
                  )
                  .format("HH:mm:ss")}
              </Text>
            </View>
          </View>

          <View style={{ marginHorizontal: 20, marginTop: 5 }}>
            <Text
              style={{
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "16",
                paddingTop: 15,
                color: "#080a61",
              }}
            >
              Number of Travelers
            </Text>
            <Text
              style={{
                paddingTop: 15,
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16",
                color: "#707070",
              }}
            >
              {rideData.numberOfPassenger}
            </Text>
          </View>

          <View
            style={{
              borderBottomColor: "black",
              borderBottomWidth: 1,
              borderStyle: "dotted",
              marginHorizontal: 17,
              marginTop: 5,
            }}
          />
          <View style={{ marginHorizontal: 20, marginTop: 5 }}>
            <Text
              style={{
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "16",
                paddingTop: 10,
                color: "#080a61",
              }}
            >
              Your Contact Details
            </Text>
            <Text
              style={{
                paddingTop: 10,
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16",
                color: "#707070",
              }}
            >
              {`${rideData.custFullName}`}
            </Text>

            <Text
              style={{
                paddingTop: 10,
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16",
                color: "#707070",
                paddingBottom: 10,
              }}
            >
              {rideData.custPhone}
            </Text>
          </View>
          <View
            style={{
              borderBottomColor: "black",
              borderBottomWidth: 1,
              borderStyle: "dotted",
              marginHorizontal: 17,
              marginTop: 5,
            }}
          />
          <View style={{ marginHorizontal: 20, marginTop: 5 }}>
            <Text
              style={{
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "16",
                paddingTop: 10,
                color: "#080a61",
              }}
            >
              Driver Details
            </Text>
            <Text
              style={{
                paddingTop: 10,
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16",
                color: "#707070",
              }}
            >
              {`${driverData.firstName} ${driverData.lastName}`}
            </Text>
            <Text
              style={{
                paddingTop: 10,
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16",
                color: "#707070",
                paddingBottom: 10,
              }}
            >
              {driverData.phone}
            </Text>
          </View>

          <View
            style={{
              borderBottomColor: "black",
              borderBottomWidth: 1,
              borderStyle: "dotted",
              marginHorizontal: 17,
              marginTop: 5,
            }}
          />
          <View style={{ marginHorizontal: 20, marginTop: 5 }}>
            <Text
              style={{
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "bold",
                fontSize: "16",
                paddingTop: 6,
                color: "#080a61",
              }}
            >
              Company Contact Details
            </Text>
            <Text
              style={{
                paddingTop: 8,
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16",
                color: "#707070",
              }}
            >
              Taxionspot
            </Text>
            <Text
              style={{
                paddingTop: 8,
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16",
                color: "#707070",
              }}
            >
              support@taxionspot.nl
            </Text>
            <Text
              style={{
                paddingTop: 10,
                whiteSpace: "nowrap",
                textAlign: "left",
                fontStyle: "normal",
                fontWeight: "normal",
                fontSize: "16",
                color: "#707070",
                paddingBottom: 10,
              }}
            >
              Houckgeeststraat 13
            </Text>
          </View>

          {/*------ FOOTER HERE ---- */}

          <View
            style={{
              flex: 1,
              backgroundColor: "#feb732",
              height: "100",
              paddingTop: 10,
              paddingLeft: 20,
              paddingRight: 20,
              paddingBottom: 10,
            }}
          >
            <>
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    whiteSpace: "nowrap",
                    textAlign: "left",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "15",
                    color: "#080a61",
                  }}
                >
                  Total Exclusives BTW
                </Text>
                <Text
                  style={{
                    whiteSpace: "nowrap",
                    textAlign: "left",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "15",
                    color: "#0001",
                  }}
                >
                  €
                  {parseFloat(
                    rideData.totalFare - 0.09 * rideData.totalFare
                  ).toFixed(2)}
                </Text>
              </View>
              <View
                style={{
                  paddingTop: 10,
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text
                  style={{
                    whiteSpace: "nowrap",
                    textAlign: "left",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "15",
                    color: "#080a61",
                  }}
                >
                  BTW (9%)
                </Text>
                <Text
                  style={{
                    whiteSpace: "nowrap",
                    textAlign: "left",
                    fontStyle: "normal",
                    fontWeight: "normal",
                    fontSize: "15",
                    color: "#0001",
                  }}
                >
                  €{parseFloat(0.09 * rideData.totalFare).toFixed(2)}
                </Text>
              </View>

              <View
                style={{
                  paddingTop: 10,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  paddingBottom: 20,
                }}
              >
                <Text
                  style={{
                    whiteSpace: "nowrap",
                    textAlign: "left",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "15",
                    color: "#080a61",
                  }}
                >
                  Total
                </Text>
                <Text
                  style={{
                    whiteSpace: "nowrap",
                    textAlign: "left",
                    fontStyle: "normal",
                    fontWeight: "bold",
                    fontSize: "15",
                    color: "#080a61",
                  }}
                >
                  €{parseFloat(rideData.totalFare).toFixed(2)}
                </Text>
              </View>
            </>
          </View>
        </View>
      </Page>
    </Document>
  );
};
export default Invoice;
