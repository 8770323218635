import React, { useState, useEffect, useContext } from 'react'
import './style.css'
import { Col } from 'reactstrap'
import { useLazyQuery, useMutation } from '@apollo/react-hooks'
import { getRideDetail, getDriverName, cancelRide } from './graphql/queries'
import { toast } from 'react-toastify'
import locationFrom from '../../images/locationFromIcon.png'
import locationTo from '../../images/locationToIcon.png'
import { navigate } from 'gatsby'
import Invoice from '../GeneratePdf'
import * as moment from 'moment'
import { PDFDownloadLink } from '@react-pdf/renderer'
import Spinner from '../Common/Spinner'
import { useMediaQuery } from 'react-responsive'
import { LanguageContext } from '../../lang'

const RideDetail = ({ state }) => {
  const {
    translations: { rideDetails }
  } = useContext(LanguageContext)
  const {
    bookingNumberText,
    outwardText,
    driverDetailsText,
    taxiDetailText,
    numOfPassengersText,
    cancelBtnText,
    totalText,
    tripDateText,
    tripTimeText,
    modelText,
    licenceText,
    colorText,
    nameText,
    phoneText,
    passengerText
  } = rideDetails
  const { rideId } = state
  const [rideDetail, setRideDetail] = useState({
    bookingNumber: '---------',
    rideDate: '--------------',
    tripTime: '--:--',
    pickUpLocation: '----------',
    destinationLocation: '-----------',
    driverName: '-----------',
    numberOfPassengers: '--',
    totalFare: '----',
    distance: '------',
    email: '----',
    phone: '-----',
    rideType: '---',
    endTime: null,
    startTime: null,
    status: '',
    taxiName: '',
    taxiModel: '',
    taxiLicenseNo: '',
    taxiColor: ''
  })
  const [isPageRendered, setIsPageRendered] = useState(false)
  const {
    bookingNumber,
    rideDate,
    tripTime,
    pickUpLocation,
    destinationLocation,
    driverName,
    numberOfPassengers,
    totalFare,
    status,
    phone,
    distance,
    taxiName,
    taxiModel,
    taxiLicenseNo,
    taxiColor
  } = rideDetail

  //ride accepted waly colors,licence,name etc

  const [rideDetailTrigger, { loading, error, data }] = useLazyQuery(
    getRideDetail
  )
  const [
    driverNameTrigger,
    { loading: driverNameLoading, error: driverNameError, data: driverNameData }
  ] = useLazyQuery(getDriverName)
  const [
    rideCancelTrigger,
    { data: rideCancelData, error: rideCancelError, loading: rideCancelLoading }
  ] = useMutation(cancelRide)
  useEffect(() => {
    rideDetailTrigger({
      variables: {
        id: rideId
      }
    })
  }, [])

  useEffect(() => {
    data &&
      setRideDetail({
        ...rideDetail,
        bookingNumber: data.ride.id.toString().slice(14, 24),
        status: data.ride.status,
        rideDate: new Date(
          parseInt(
            data.ride.rideType === 'scheduled_ride'
              ? data.ride.pickupDate
              : data.ride.date
          )
        ).toDateString(),
        tripTime:
          data.ride.startTime !== null
            ? moment
                .utc(
                  moment(parseInt(data.ride.endTime)).diff(
                    moment(parseInt(data.ride.startTime))
                  )
                )
                .format('HH:mm:ss')
            : 'Not Started',
        pickUpLocation: data.ride.pickupLoc,
        destinationLocation: data.ride.destLoc,
        driverName: '-------',
        numberOfPassengers: data.ride.numberOfPassenger,
        totalFare: data.ride.totalFare,
        distance: data.ride.distance,
        rideType: data.ride.rideType,
        endTime: data.ride.endTime,
        startTime: data.ride.startTime
      })
    data && data.ride.driverId === null
      ? toast.warn('Driver is Not Assigned for this ride', {
          autoClose: 1200
        })
      : data &&
        driverNameTrigger({
          variables: {
            id: data.ride.driverId
          }
        })
  }, [data])
  useEffect(() => {
    driverNameData &&
      setRideDetail({
        ...rideDetail,
        driverName: `${driverNameData.driver.firstName} ${driverNameData.driver.lastName}`,
        phone: driverNameData.driver.phone,
        taxiName: driverNameData.driver.taxiName,
        taxiModel: driverNameData.driver.taxiModel,
        taxiLicenseNo: driverNameData.driver.taxiLicenseNo,
        taxiColor: driverNameData.driver.taxiColor
      })
    setIsPageRendered(true)
  }, [driverNameData])
  if (loading) {
    console.log('graphql loading', loading)
  }
  if (error) {
    toast.error(error.message, {
      autoClose: 1200
    })
  }
  if (driverNameError) {
    toast.error(driverNameError.message, {
      autoClose: 1200
    })
  }
  if (data) {
    console.log('data recived from graphql is', data.ride)
  }
  if (driverNameData) {
    console.log('data recived from graphql is', driverNameData)
  }
  const handleRideCancel = () => {
    rideCancelTrigger({
      variables: {
        id: rideId
      }
    })
  }
  if (rideCancelLoading) {
    console.log('Ride Cancel Mutation State', rideCancelLoading)
  }
  if (rideCancelError) {
    rideCancelError &&
      toast.error(rideCancelError.graphQLErrors[0].message.message, {
        autoClose: 1200
      })
  }
  if (rideCancelData) {
    console.log('ride cancel mutation result', rideCancelData)
    navigate('/my-bookings')
  }
  const handleEditButton = () => {
    navigate('/ride-detail-update', {
      state: {
        rideId: data && data.ride.id
      }
    })
  }
  let splitLocation1 = pickUpLocation.split(',')
  let firstValue1 = splitLocation1[0]
  let restOfArray1 = splitLocation1.slice(1).toString()

  let splitLocation2 = destinationLocation.split(',')
  let firstValue2 = splitLocation2[0]
  let restOfArray2 = splitLocation2.slice(1).toString()
  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 })

  return (
    <div className='container-fluid d-flex  hm-login-bg justify-content-center justify-content-center align-items-center'>
      <div id='dashboard'>
        <div className='blueBackgroundHeader'></div>
        <div className='absolutePostionBox py-4'>
          {loading ? (
            <div className='container-fluid'>
              <Spinner />
            </div>
          ) : (
            <>
              <div className='container-fluid row my-3 px-5'>
                <p className='bookingHeading col-10'>
                  {bookingNumberText} #{bookingNumber.toUpperCase()}
                </p>
                {driverNameData &&
                rideDetail.endTime !== null &&
                status !== 'cancelled' ? (
                  <div>
                    <PDFDownloadLink
                      document={
                        <Invoice
                          driverData={driverNameData.driver}
                          rideData={data.ride}
                          bookingNumber={bookingNumber}
                        />
                      }
                      fileName={`${rideId}.pdf`}
                    >
                      {({ blob, url, loading, error }) =>
                        loading ? 'Loading document...' : 'Download now!'
                      }
                    </PDFDownloadLink>
                  </div>
                ) : null}
              </div>
              {/* <div className='container-fluid row head-bar mx-0 px-5'>
                <div className='col-10 px-0'>
                  <h2 className='col-10 mx-0 px-0'>Outward ride</h2>
                  <h4 className='col-12 mx-0 px-0'>{rideDate}</h4>
                </div>
                <div className='col-2 px-0'>
                  <p className='col-2 lead ridePrice'>$ 1334242{totalFare}</p>
                </div>
              </div> */}
              <div className='container-fluid no-gutters header'>
                <div className='row '>
                  <div className='col-7 col-md-10 outwardText'>
                    {outwardText}
                  </div>
                  <div className='col-5 col-md-2 headerPriceText'>
                    € {totalFare}
                  </div>
                </div>
                <div className='col-sm rideDatetext'>
                  <h4 className='col-12 mx-0 px-0'>{rideDate}</h4>
                </div>
              </div>

              <div className='container-fluid row my-3 '>
                <div className='col-lg-6 col-md-6 col-sm-12'>
                  <div className='col-12 d-flex justify-content-center  py-2'>
                    <div className='col-md-1 col-sm-3 col-3 '>
                      <img className='imageIconStyle' src={locationFrom} />
                    </div>
                    <div className=' col-md-11 col-sm-9 col-9 d-flex flex-column'>
                      <p className='locationHeading my-1'>{firstValue1}</p>
                      <p className='locationDescription'>{restOfArray1}</p>
                    </div>
                  </div>
                  <div className='col-12 d-flex justify-content-center '>
                    <div className='col-md-1 col-sm-3 col-3'>
                      <img className='imageIconStyle' src={locationTo} />
                    </div>
                    <div className='col-md-11 col-sm-9 col-9  d-flex flex-column'>
                      <p className='locationHeading my-1'>{firstValue2}</p>
                      <p className='locationDescription'>{restOfArray2}</p>
                    </div>
                  </div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12 '>
                  <Col lg='4'>
                    <div className='container-fluid d-flex align-items-center justify-content-between px-0'>
                      <p className='tripDetailHeading '>{tripDateText}</p>
                      {rideDetail.rideType === 'scheduled_ride' &&
                      status !== 'missed' &&
                      status !== 'cancelled' &&
                      rideDetail.startTime === null ? (
                        <p
                          onClick={() => handleEditButton()}
                          className='editButton mx-3 px-3 py-1 justify-content-center align-self-center editButton'
                        >
                          <span className='editButtonText'>Edit</span>
                        </p>
                      ) : null}
                    </div>
                    <p className='tripDetailDescription'>{rideDate}</p>
                  </Col>

                  <Col lg='4'>
                    <p className='tripDetailHeading'>{tripTimeText}</p>
                    <p className='tripDetailDescription'>{tripTime}</p>
                  </Col>
                </div>
              </div>
              <div className='container-fluid my-3 px-5'>
                <p className='accountDetailHeading my-2'>{driverDetailsText}</p>
                <div className='accountDetailDescription'>
                  <p className='detailFields'>
                    {nameText}: {driverName}
                  </p>
                  <p className='detailFields'>
                    {phoneText}: {phone}
                  </p>
                </div>
                <p className='accountDetailHeading mb-1 mt-3'>
                  {taxiDetailText}
                </p>
                <div className='accountDetailDescription'>
                  <p className='detailFields'>
                    {nameText}: {taxiName}
                  </p>
                  <p className='detailFields'>
                    {licenceText} #: {taxiLicenseNo}
                  </p>
                  <p className='detailFields'>
                    {modelText}: {taxiModel}
                  </p>{' '}
                  <p className='detailFields'>
                    {colorText}: {taxiColor}
                  </p>
                </div>

                <p className='accountDetailHeading mt-3 mb-0'>
                  {numOfPassengersText}
                </p>
                <p className='accountDetailDescription'>
                  {numberOfPassengers} {passengerText}
                </p>
                {rideDetail.rideType === 'scheduled_ride' &&
                status !== 'missed' &&
                status !== 'cancelled' &&
                rideDetail.startTime === null ? (
                  <div
                    onClick={() => handleRideCancel()}
                    className='deleteButton py-1 mt-5'
                  >
                    <p className='buttonText'>{cancelBtnText}</p>
                  </div>
                ) : null}
              </div>

              <div className='container-fluid no-gutters footer'>
                <div className='row d-flex align-items-center '>
                  <div className='col-sm-3 col-md-3 totalText'>{totalText}</div>
                  {isDesktopOrLaptop ? (
                    <div className='col-sm-12 col-md-9 footerPriceText'>
                      <span>€{totalFare}</span>
                    </div>
                  ) : (
                    <div className='col-sm-12 col-md-9 footerPriceText2'>
                      <span>€ {totalFare}</span>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export { RideDetail }
